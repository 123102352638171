var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { DatePickerInputWithIcon } from 'components/datepicker/DatePickerInputWithIcon';
import { Label } from 'components/label/Label.component';
import { List } from 'components/list/List.component';
import { ConfirmDialog } from 'pages/priceListImport/confirmDialog/ConfirmDialog.component';
import { usePriceListApi } from 'pages/priceListImport/usePriceListApi';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { immutableSort } from 'utils/immutableSort';
import './rangeOfModels.css';
import { formatDate } from 'utils/formatDate';
import { format } from 'date-fns';
import SessionStorage from 'common/sessionStorage';
export function sameMembers(arr1, arr2) {
    var set1 = new Set(arr1);
    var set2 = new Set(arr2);
    return arr1.every(function (item) { return set2.has(item); }) && arr2.every(function (item) { return set1.has(item); });
}
export var RangeOfModels = withCancellation(function (_a) {
    var cancelPopupMessage = _a.cancelPopupMessage, currentPriceListName = _a.currentPriceListName, handleGoToStepByValue = _a.handleGoToStepByValue;
    var _b = useState(undefined), models = _b[0], setModels = _b[1];
    var _c = useState([]), selectedModels = _c[0], setSelectedModels = _c[1];
    var _d = useState(false), allModelsChecked = _d[0], setAllModelsChecked = _d[1];
    var _e = useState(false), openConfirmDialog = _e[0], setOpenConfirmDialog = _e[1];
    var history = useHistory();
    var isEmployeeLeasing = SessionStorage.get('employeeLeasingContext');
    var priceListsApi = isEmployeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    var setStatus = useStatus().setStatus;
    useEffect(function () {
        function getModelsLists() {
            return __awaiter(this, void 0, void 0, function () {
                var modelsListResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, priceListsApi.getAvailableModel(currentPriceListName)];
                        case 1:
                            modelsListResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setModels(modelsListResponse);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getModelsLists();
    }, []);
    var sortedModelsList = models && immutableSort(models, function (modelA, modelB) { return modelA.name.localeCompare(modelB.name); });
    function handleCheckAllModels(checked) {
        if (checked) {
            setAllModelsChecked(true);
            var modelsToSelect_1 = models.filter(function (model) { return !selectedModels.find(function (selectedModel) { return selectedModel.id === model.id; }); }).map(function (element) {
                var checkedModel = models.find(function (model) { return model.id === element.id; });
                return {
                    id: element.id,
                    fromDate: checkedModel && checkedModel.fromDate ? checkedModel.fromDate : null
                };
            });
            setSelectedModels(function (currentSelectedModels) {
                return __spreadArray(__spreadArray([], currentSelectedModels, true), modelsToSelect_1, true);
            });
        }
        if (!checked) {
            setAllModelsChecked(false);
            setSelectedModels([]);
        }
    }
    function handleCheckModel(checked, modelId) {
        if (checked) {
            var checkedModel = models.find(function (model) { return model.id === modelId; });
            var selectedModelToAdd_1 = {
                id: modelId,
                fromDate: checkedModel && checkedModel.fromDate ? checkedModel.fromDate : null
            };
            setSelectedModels(function (currentSelectedModels) { return __spreadArray(__spreadArray([], currentSelectedModels, true), [selectedModelToAdd_1], false); });
            if (__spreadArray(__spreadArray([], selectedModels, true), [selectedModelToAdd_1], false).length !== sortedModelsList.length) {
                return;
            }
            else {
                setAllModelsChecked(sameMembers(__spreadArray(__spreadArray([], selectedModels, true), [selectedModelToAdd_1], false).map(function (model) { return model.id; }), sortedModelsList.map(function (model) { return model.id; })));
            }
        }
        if (!checked) {
            setSelectedModels(function (currentSelectedModels) {
                return __spreadArray([], currentSelectedModels, true).filter(function (model) { return model.id !== modelId; });
            });
            setAllModelsChecked(false);
        }
    }
    function handleOnCancelClick() {
        setOpenConfirmDialog(true);
    }
    function handleOnConfirmDialog() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListsApi.cancelImport(currentPriceListName)];
                    case 1:
                        _a.sent();
                        setOpenConfirmDialog(false);
                        SessionStorage.remove('employeeLeasingContext');
                        history.push('/price-lists');
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnCancelDialog() {
        setOpenConfirmDialog(false);
    }
    function handleOnNextStepButtonClick() {
        return __awaiter(this, void 0, void 0, function () {
            var putSelectedModelsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.putSelectedModels(currentPriceListName, selectedModels)];
                    case 1:
                        putSelectedModelsResponse = _a.sent();
                        if (!(putSelectedModelsResponse && putSelectedModelsResponse.status === 200)) return [3 /*break*/, 2];
                        setStatus('success');
                        handleGoToStepByValue('import-pricelist-data');
                        return [3 /*break*/, 4];
                    case 2:
                        setStatus('error');
                        return [4 /*yield*/, priceListsApi.cancelImport(currentPriceListName)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleChangeEffectiveDate(value, modelId) {
        setSelectedModels(function (currentSelectedModels) {
            var editedSelectedModel = currentSelectedModels.findIndex(function (model) { return model.id === modelId; });
            return __spreadArray(__spreadArray(__spreadArray([], currentSelectedModels.slice(0, editedSelectedModel), true), [
                __assign(__assign({}, currentSelectedModels[editedSelectedModel]), { fromDate: format(new Date(value), 'yyyy-MM-dd') })
            ], false), currentSelectedModels.slice(editedSelectedModel + 1), true);
        });
    }
    function isModelChecked(modelId) {
        return selectedModels.some(function (model) { return model.id === modelId; });
    }
    function haveAllCheckedModelsDateFromSet() {
        return selectedModels.every(function (model) { return model.fromDate; });
    }
    var isNextStepButtonDisabled = selectedModels.length > 0 && haveAllCheckedModelsDateFromSet();
    function getSelectedModel(modelId) {
        return selectedModels.find(function (selectedModel) { return selectedModel.id === modelId; });
    }
    return (React.createElement("div", { "data-testid": "range-of-models-wrapper" },
        models ?
            React.createElement("div", { className: 'rangeOfModelsList' },
                React.createElement(List.Root, null,
                    React.createElement(List.Item, { cursor: 'pointer', onClick: function (event) {
                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                return;
                            }
                            handleCheckAllModels(!allModelsChecked);
                        } },
                        React.createElement("div", { className: 'modelElementCheckbox' },
                            React.createElement(Checkbox.Root, { checked: allModelsChecked, value: 'all', id: 'all', onCheckedChange: handleCheckAllModels },
                                React.createElement(Checkbox.Indicator, null)),
                            React.createElement(Label, { htmlFor: 'all' }, "Zaznacz wszystkie"))),
                    sortedModelsList.map(function (model) { return (React.createElement(List.Item, { key: model.id, height: 'fixed', cursor: 'default' },
                        React.createElement("div", { className: 'modelElement' },
                            React.createElement("div", { className: 'modelElementCheckbox' },
                                React.createElement(Checkbox.Root, { checked: selectedModels.find(function (selectedModel) { return selectedModel.id === model.id; }) ? true : false, value: model.id, id: model.id, onCheckedChange: function (checked) { return handleCheckModel(checked, model.id); } },
                                    React.createElement(Checkbox.Indicator, null)),
                                React.createElement(Label, { htmlFor: model.id }, model.name)),
                            model.fromDate ?
                                React.createElement("div", { className: 'modelElementDateFrom' },
                                    React.createElement("span", null, "Data obowi\u0105zywania cennika"),
                                    React.createElement("span", null, formatDate(new Date(model.fromDate))))
                                :
                                    isModelChecked(model.id) ?
                                        React.createElement("div", { className: 'modelElementDate' },
                                            React.createElement("div", null, "Data obowi\u0105zywania cennika"),
                                            React.createElement("div", { className: 'modelElementDatePicker', "data-testid": "date-picker-wrapper" },
                                                React.createElement(DatePickerInputWithIcon, { date: getSelectedModel(model.id).fromDate ? new Date(getSelectedModel(model.id).fromDate) : null, dateOnChangeHandler: function (value) {
                                                        return handleChangeEffectiveDate(value, model.id);
                                                    }, filterWeekend: false, maxDate: new Date(model.maxDate) })))
                                        : null))); }))) : null,
        React.createElement("div", { className: "rangeOfModelsActionsWrapper" },
            React.createElement("div", { className: "rangeOfModelsButtonWrapper" },
                React.createElement(Button, { type: "button", variant: "secondary", onClick: handleOnCancelClick }, "Anuluj")),
            React.createElement("div", { className: "rangeOfModelsButtonWrapper" },
                React.createElement(Button, { type: "button", onClick: handleOnNextStepButtonClick, disabled: !isNextStepButtonDisabled }, "Dalej"))),
        React.createElement(ConfirmDialog, { open: openConfirmDialog, message: cancelPopupMessage, onCancel: handleOnCancelDialog, onConfirm: handleOnConfirmDialog })));
});
