// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .extraSaleDiscountViewSection {
    width: 1280px;
    position: relative;
}

#new-dol .extraSaleDiscountViewSectionTitleWrapper {
    background: var(--background-very-light-grey); 
    border-top: 1px solid var(--line-light-grey); 
    padding: 12px 16px;
}

#new-dol .extraSaleDiscountViewSecitionTitle {
    color: var(--main-text-color);
    text-transform: uppercase;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/extraSaleDiscounts/viewSection/viewSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,6CAA6C;IAC7C,4CAA4C;IAC5C,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;AACb","sourcesContent":[".extraSaleDiscountViewSection {\n    width: 1280px;\n    position: relative;\n}\n\n.extraSaleDiscountViewSectionTitleWrapper {\n    background: var(--background-very-light-grey); \n    border-top: 1px solid var(--line-light-grey); \n    padding: 12px 16px;\n}\n\n.extraSaleDiscountViewSecitionTitle {\n    color: var(--main-text-color);\n    text-transform: uppercase;\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: 500;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
