export function versionOptionsOrdering(versionName) {
    var lowerCaseVersionName = versionName.toLowerCase();
    switch (lowerCaseVersionName) {
        case 'essential':
            return 0;
        case 'core':
            return 1;
        case 'plus':
            return 2;
        case 'ultra':
            return 3;
        case 'ultimate':
            return 4;
        default:
            return undefined;
    }
}
