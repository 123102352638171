// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .drsDiscountWrapper {
    padding: 32px 16px;
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .drsDiscountTable {
    width: 100%;
}

#new-dol .drsDiscountHeadCell {
    font-weight: 500;
    padding: 6px 8px;
}

#new-dol .drsDiscountTableRow:nth-child(odd) {
    background-color: var(--background-very-light-grey);
}

#new-dol .drsDiscountBodyCell {
    padding: 14px 8px;
}

#new-dol .drsDiscountDealerCell {
    padding-left: 16px;
    width: 30%;
}

#new-dol .drsDiscountCarCell {
    width: 25%;
}

#new-dol .drsDiscountDiscountAmountNetCell {
    width: 20%;
}

#new-dol .drsDiscountStatusCell {
    width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/pages/extraSaleDiscounts/drsDiscountSection/drsDiscountSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".drsDiscountWrapper {\n    padding: 32px 16px;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.drsDiscountTable {\n    width: 100%;\n}\n\n.drsDiscountHeadCell {\n    font-weight: 500;\n    padding: 6px 8px;\n}\n\n.drsDiscountTableRow:nth-child(odd) {\n    background-color: var(--background-very-light-grey);\n}\n\n.drsDiscountBodyCell {\n    padding: 14px 8px;\n}\n\n.drsDiscountDealerCell {\n    padding-left: 16px;\n    width: 30%;\n}\n\n.drsDiscountCarCell {\n    width: 25%;\n}\n\n.drsDiscountDiscountAmountNetCell {\n    width: 20%;\n}\n\n.drsDiscountStatusCell {\n    width: 25%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
