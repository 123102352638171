// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .extraSaleDiscountApplicationReasonsWrapper {
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .extraSaleDiscountApplicationReasonsTable {
    width: 100%;
}

#new-dol .extraSaleDiscountApplicationReasonsTableHeader {
    font-weight: 400;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 4px;
    padding-right: 4px;
}

#new-dol .extraSaleDiscountApplicationReasonsTableRow:nth-child(odd) {
    background-color: var(--background-very-light-grey);
}

#new-dol .extraSaleDiscountApplicationReasonsTableCell {
    padding-top: 14px;
    padding-bottom: 16px;
    padding-left: 4px;
    padding-right: 4px;
}

#new-dol .extraSaleDiscountApplicationReasonsReasonCell {
    padding-left: 16px;
    width: 40%;
    vertical-align: top;
}

#new-dol .extraSaleDiscountApplicationReasonsRequestedAmountCell {
    width: 30%;
    vertical-align: top;
}

#new-dol .extraSaleDiscountApplicationReasonsDiscountPercentCell {
    width: 30%;
    vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/pages/extraSaleDiscounts/extraSaleDiscountApplicationSection/applicationReasons.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".extraSaleDiscountApplicationReasonsWrapper {\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.extraSaleDiscountApplicationReasonsTable {\n    width: 100%;\n}\n\n.extraSaleDiscountApplicationReasonsTableHeader {\n    font-weight: 400;\n    padding-top: 6px;\n    padding-bottom: 6px;\n    padding-left: 4px;\n    padding-right: 4px;\n}\n\n.extraSaleDiscountApplicationReasonsTableRow:nth-child(odd) {\n    background-color: var(--background-very-light-grey);\n}\n\n.extraSaleDiscountApplicationReasonsTableCell {\n    padding-top: 14px;\n    padding-bottom: 16px;\n    padding-left: 4px;\n    padding-right: 4px;\n}\n\n.extraSaleDiscountApplicationReasonsReasonCell {\n    padding-left: 16px;\n    width: 40%;\n    vertical-align: top;\n}\n\n.extraSaleDiscountApplicationReasonsRequestedAmountCell {\n    width: 30%;\n    vertical-align: top;\n}\n\n.extraSaleDiscountApplicationReasonsDiscountPercentCell {\n    width: 30%;\n    vertical-align: top;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
