import React from 'react';
import ModelDictionary from 'common/modelDictionary';
import './orderingPartySection.css';
import { formatDate } from 'utils/formatDate';
export function OrderingPartyPreviewSection(_a) {
    var orderingPartyPreviewData = _a.orderingPartyPreviewData;
    var cars = (orderingPartyPreviewData === null || orderingPartyPreviewData === void 0 ? void 0 : orderingPartyPreviewData.partyCars)
        ? orderingPartyPreviewData.partyCars.map(function (car) {
            var modelCode = car.modelId.split('_')[0];
            return {
                handoverDate: car.handoverDate,
                vin: car.vin,
                carModel: ModelDictionary.nameFor(modelCode),
                modelYear: car.modelId.split('_')[1],
            };
        })
        : [];
    return (React.createElement("div", { className: 'orderingPartySectionWrapper', "data-testid": 'ordering-party-section' },
        React.createElement("div", { className: 'orderingPartySectionContener' },
            React.createElement("div", { className: 'orderingPartySection' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Strona klienta:"),
                (orderingPartyPreviewData === null || orderingPartyPreviewData === void 0 ? void 0 : orderingPartyPreviewData.party) ?
                    React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-value" },
                        orderingPartyPreviewData.party.name,
                        " ",
                        orderingPartyPreviewData.party.identificationNumber.type,
                        ": ",
                        orderingPartyPreviewData.party.identificationNumber.value)
                    : null),
            React.createElement("div", { className: 'orderingPartySection' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Samochody:"),
                React.createElement("div", { className: "orderingPartySectionCarsTableWrapper", "data-testid": 'ordering-party-section-cars-table-wrapper' },
                    React.createElement("table", { className: "orderingPartySectionCarsTable" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: "orderingPartySectionCarTableHeader orderingPartySectionCarOrderDateCell" }, "Data wydania"),
                                React.createElement("th", { className: "orderingPartySectionCarTableHeader" }, "Model"),
                                React.createElement("th", { className: "orderingPartySectionCarTableHeader orderingPartySectionCarVinCell" }, "VIN"))),
                        React.createElement("tbody", null, cars && cars.length > 0
                            ? cars.map(function (car) { return (React.createElement("tr", { className: "orderingPartySectionCarTableRow", key: car.vin },
                                React.createElement("td", { className: "orderingPartySectionCarTableCell orderingPartySectionCarOrderDateCell" }, car.handoverDate ? formatDate(new Date(car.handoverDate)) : ''),
                                React.createElement("td", { className: "orderingPartySectionCarTableCell" }, "".concat(car.carModel, " (").concat(car.modelYear, ")")),
                                React.createElement("td", { className: "orderingPartySectionCarTableCell orderingPartySectionCarVinCell" }, car.vin))); })
                            : null)))))));
}
