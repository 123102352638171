import React from 'react';
import './vcpDiscountSettlement.css';
import { vcpDiscountName } from 'common/vcpDiscountName';
import { TextAmount } from 'components/textAmount/TextAmount';
import { fundingName } from 'common/fundingName';
import { settlementStatus } from 'common/vcpSettlementStatus';
export function VcpDiscountSettlement(_a) {
    var settlementsOfOrder = _a.settlementsOfOrder;
    return (React.createElement("div", { className: 'vcpDiscountSettlementWrapper', "data-testid": 'vcp-discount-settlement-wrapper' },
        React.createElement("table", { className: "vcpDiscountSettlementTable" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementOrderDateCell", rowSpan: 2 }, "Data zam\u00F3wienia"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementDiscountGroupCell", rowSpan: 2 }, "Grupa rabatowa VCP"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementCatalogPriceNetCell", rowSpan: 2 }, "Cena katalogowa netto"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementClientDiscountNetCell", rowSpan: 2 }, "Rabat klienta netto"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementVcpDiscountNetCell", colSpan: 2 }, "Rabat VCP"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementFundingCell", rowSpan: 2 }, "Finansowanie"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementStatusCell", rowSpan: 2 }, "Status")),
                React.createElement("tr", null,
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlemenClientDiscountPercent" }, "%"),
                    React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlemenAmountNet" }, "kwota netto"))),
            React.createElement("tbody", null, settlementsOfOrder &&
                React.createElement("tr", { className: 'vcpDiscountSettlementTableRow' },
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementOrderDateCell" }, settlementsOfOrder.orderDate),
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementDiscountGroupCell" }, vcpDiscountName(settlementsOfOrder.discount)),
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementCatalogPriceNetCell" },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlementsOfOrder.listPrice)),
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementClientDiscountNetCell" },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlementsOfOrder.clientDiscountNet)),
                    React.createElement("td", { className: 'vcpDiscountSettlementBodyCell vcpDiscountSettlemenClientDiscountPercent' },
                        settlementsOfOrder.producerPercentDiscount,
                        " %"),
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlemenAmountNet" },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlementsOfOrder.vcpDiscountNet)),
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementFundingCell" }, fundingName(settlementsOfOrder.funding)),
                    React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementStatusCell" }, settlementStatus(settlementsOfOrder.status)))))));
}
