import { TextAmount } from 'components/textAmount/TextAmount';
import React from 'react';
import _ from 'lodash';
import './applicationReasons.css';
function formatNumber(value) {
    if (_.isFinite(value)) {
        return new Intl.NumberFormat('pl-PL', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(value);
    }
    else {
        return undefined;
    }
}
export function ApplicationReasons(_a) {
    var applicationReasons = _a.applicationReasons, carNetListPrice = _a.carNetListPrice;
    var acumulateRequestedAmount = applicationReasons.reduce(function (acc, cur) { return acc + cur.requestedNetDiscount; }, 0);
    return (React.createElement("div", { className: 'extraSaleDiscountApplicationReasonsWrapper', "data-testid": 'application-reasons-wrapper' },
        React.createElement("table", { className: "extraSaleDiscountApplicationReasonsTable" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "extraSaleDiscountApplicationReasonsTableHeader extraSaleDiscountApplicationReasonsReasonCell" }, "Pow\u00F3d"),
                    React.createElement("th", { className: "extraSaleDiscountApplicationReasonsTableHeader extraSaleDiscountApplicationReasonsRequestedAmountCell" }, "Oczekiwana kwota"),
                    React.createElement("th", { className: "extraSaleDiscountApplicationReasonsTableHeader extraSaleDiscountApplicationReasonsDiscountPercentCell" }, "Warto\u015B\u0107 rabatu (%)"))),
            React.createElement("tbody", null,
                applicationReasons.map(function (applicationReason, index) { return (React.createElement("tr", { className: "extraSaleDiscountApplicationReasonsTableRow", key: index },
                    React.createElement("td", { className: "extraSaleDiscountApplicationReasonsTableCell extraSaleDiscountApplicationReasonsReasonCell" }, applicationReason.name),
                    React.createElement("td", { className: "extraSaleDiscountApplicationReasonsTableCell extraSaleDiscountApplicationReasonsRequestedAmountCell" },
                        React.createElement(TextAmount, { textAlignEnd: false }, applicationReason.requestedNetDiscount)),
                    React.createElement("td", { className: "extraSaleDiscountApplicationReasonsTableCell extraSaleDiscountApplicationReasonsDiscountPercentCell" }, formatNumber(applicationReason.requestedNetDiscount / carNetListPrice * 100)))); }),
                React.createElement("tr", { style: { backgroundColor: '#f2f2f2' }, className: "extraSaleDiscountApplicationReasonsTableRow", key: 'summary' },
                    React.createElement("td", { className: "extraSaleDiscountApplicationReasonsTableCell extraSaleDiscountApplicationReasonsReasonCell" }, "Suma"),
                    React.createElement("td", { className: "extraSaleDiscountApplicationReasonsTableCell extraSaleDiscountApplicationReasonsRequestedAmountCell" },
                        React.createElement(TextAmount, { textAlignEnd: false }, acumulateRequestedAmount)),
                    React.createElement("td", { className: "extraSaleDiscountApplicationReasonsTableCell extraSaleDiscountApplicationReasonsDiscountPercentCell" }, formatNumber((acumulateRequestedAmount / carNetListPrice) * 100)))))));
}
