import { useHttp } from 'http/httpService';
export function useExtraSaleDiscountApi() {
    var http = useHttp();
    return {
        getExtraSaleDiscountData: function (extraSaleDiscountId) {
            return http
                .get("/api/extra-sale-discounts/".concat(extraSaleDiscountId))
                .then(function (response) { return response.data; });
        },
        getUserCardData: function (userId) {
            return http
                .get("/api/users/".concat(userId, "?card"))
                .then(function (response) { return response.data; });
        },
        getOrganizationData: function (organizationId) {
            return http
                .get("/api/organizations/".concat(organizationId))
                .then(function (response) { return response.data; });
        },
        getPartyCars: function (identificationNumber, from, to) {
            return http
                .get("/api/cars?byPartyIdentificationNumber=".concat(identificationNumber, "&from=").concat(from, "&to=").concat(to))
                .then(function (response) { return response.data; });
        },
        loadLocation: function (location) {
            return http
                .get('/api' + location, { responseType: 'blob' });
        },
        getActiveVcpDiscounts: function (identificationNumber) {
            return http
                .get("/api/concessions?active&forIdentificationNumber=".concat(identificationNumber))
                .then(function (response) { return response.data; });
        },
        getVcpDiscountSettlementOfOrder: function (orderId) {
            return http.get("/api/concessions/settlements?ofOrder=".concat(orderId))
                .then(function (response) { return response.data; });
        },
        getDrsDiscountsForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/extra-sale-discounts?forIdentificationNumber=".concat(identificationNumber))
                .then(function (response) { return response.data; });
        },
        getCarDefinition: function (commonOrderNumber) {
            return http
                .get("/api/car-definitions/".concat(commonOrderNumber))
                .then(function (response) { return response.data; });
        }
    };
}
