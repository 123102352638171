export function warningsDictionary(warning) {
    switch (warning) {
        case 'model-year-changed':
            return 'Zmiana rocznika modelowego - Konfiguracja zostanie przedstawiona wg cennika dla roku modelowego odpowiadającego dacie zamówienia.';
        case 'freezed-specification':
            return 'Minęła data zamrożenia specyfikacji.';
        default:
            return warning;
    }
}
