// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .activeVcpDiscountsWrapper {
    padding: 32px 16px;
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .activeVcpDiscountsTable {
    width: 100%;
}

#new-dol .activeVcpDiscountsHeadCell {
    font-weight: 500;
    padding: 6px 16px;
}

#new-dol .activeVcpDiscountsBodyCell {
    padding: 14px 16px;
}

#new-dol .activeVcpDiscountsTableRow:nth-child(odd) {
    background-color: var(--background-very-light-grey);
}

#new-dol .activeVcpDiscountsNumberCell {
    width: 15%;
}

#new-dol .activeVcpDiscountsDealerCell {
    width: 30%;
}

#new-dol .activeVcpDiscountsGroupCell {
    width: 43%;
}

#new-dol .activeVcpDiscountsValidToCell {
    width: 12%;
}

#new-dol .activeVcpDiscountsLink {
    color: var(--blue);
}`, "",{"version":3,"sources":["webpack://./src/pages/extraSaleDiscounts/activeVcpDiscounts/activeVcpDiscounts.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".activeVcpDiscountsWrapper {\n    padding: 32px 16px;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.activeVcpDiscountsTable {\n    width: 100%;\n}\n\n.activeVcpDiscountsHeadCell {\n    font-weight: 500;\n    padding: 6px 16px;\n}\n\n.activeVcpDiscountsBodyCell {\n    padding: 14px 16px;\n}\n\n.activeVcpDiscountsTableRow:nth-child(odd) {\n    background-color: var(--background-very-light-grey);\n}\n\n.activeVcpDiscountsNumberCell {\n    width: 15%;\n}\n\n.activeVcpDiscountsDealerCell {\n    width: 30%;\n}\n\n.activeVcpDiscountsGroupCell {\n    width: 43%;\n}\n\n.activeVcpDiscountsValidToCell {\n    width: 12%;\n}\n\n.activeVcpDiscountsLink {\n    color: var(--blue);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
