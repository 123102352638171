import React from 'react';
import '../extraSaleDiscount.css';
import { ViewSection } from '../viewSection/ViewSection';
import { ApplicantSection } from '../applicantSection/ApplicantSection';
import { OrderingPartyPreviewSection } from '../orderingPartySection/OrderingPartyPreviewSection';
import { CarSalePotentialPreview } from '../carSalePotentialSection/CarSalePotentialPreviewSection';
import { ExtraSaleDiscountApplication } from '../extraSaleDiscountApplicationSection/ExtraSaleDiscountApplicationPreviewSection';
import { AccordionViewSection } from '../accordionViewSection/AccordionViewSection';
import { ActiveVcpDiscounts } from '../activeVcpDiscounts/ActiveVcpDiscounts';
import { VcpDiscountSettlement } from '../vcpDiscountSettlement/VcpDiscountSettlement';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { DrsDiscountSection } from '../drsDiscountSection/DrsDiscountSection';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import { Button } from 'components/button/Button.component';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from 'components/spinner/Spinner';
import { useStatus } from 'common/statusContext';
import { useExtraSaleDiscountPreview } from './useExtraSaleDiscountPreview';
export var ExtraSaleDiscountPreview = withCancellation(function () {
    var history = useHistory();
    var location = useLocation();
    var status = useStatus().status;
    var _a = location.search.split('?'), searchParamsString = _a[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var extraSaleDiscountId = searchParams.get('extraSaleDiscountId');
    var _b = useExtraSaleDiscountPreview({ extraSaleDiscountId: extraSaleDiscountId }), extraSaleDiscountApplication = _b.extraSaleDiscountApplication, applicantCard = _b.applicantCard, organizationData = _b.organizationData, partyCars = _b.partyCars, settlementsOfOrder = _b.settlementsOfOrder, drsDiscountsForParty = _b.drsDiscountsForParty, activeVcpDiscounts = _b.activeVcpDiscounts;
    var applicantSectionData = organizationData && applicantCard && extraSaleDiscountApplication && {
        organizationName: organizationData.organizationName,
        applicantName: applicantCard.name,
        applicantEmail: applicantCard.email,
        applicantPhone: applicantCard.phoneNumber,
        applicationNumber: extraSaleDiscountApplication.number
    };
    var orderingPartyPreviewData = extraSaleDiscountApplication && {
        party: extraSaleDiscountApplication.party,
        partyCars: partyCars
    };
    var carSalePotentialSection = extraSaleDiscountApplication && {
        carsSalePotential: extraSaleDiscountApplication.carsSalePotential
    };
    var extraSaleDiscountApplicationPreviewSection = extraSaleDiscountApplication && {
        car: extraSaleDiscountApplication.car,
        changes: extraSaleDiscountApplication.changes,
        funding: extraSaleDiscountApplication.funding,
        applicationReasons: extraSaleDiscountApplication.applicationReasons,
        attachments: extraSaleDiscountApplication.attachments,
    };
    function onCloseClick() {
        if (location.pathname === '/special-offer/search-preview') {
            history.push('/special-offer/search-preview');
            return;
        }
    }
    return (React.createElement("div", { className: 'extraSaleDiscountWrapper' },
        React.createElement(ViewSection, { title: 'wnioskuj\u0105cy', dataTestid: 'applicant-section-wrapper' },
            React.createElement(ApplicantSection, { applicantSectionData: applicantSectionData })),
        React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: 'client-section' },
            React.createElement(OrderingPartyPreviewSection, { orderingPartyPreviewData: orderingPartyPreviewData })),
        React.createElement(ViewSection, { title: 'potencja\u0142 sprzeda\u017Cowy', "data-testid": 'cars-sale-potential-section' },
            React.createElement(CarSalePotentialPreview, { carSalePotentialSection: carSalePotentialSection })),
        React.createElement(ViewSection, { title: 'rabat drs', "data-testid": 'extra-sale-discount-section-wrapper' },
            React.createElement(ExtraSaleDiscountApplication, { extraSaleDiscountApplicationPreviewSection: extraSaleDiscountApplicationPreviewSection })),
        React.createElement(AccordionViewSection.Root, { defaultValue: ['active-vcp-discounts-for-party', 'vcp-discount-settelements-for-car', 'other-extra-sale-discounts-for-party'] },
            React.createElement(AccordionViewSection.Item, { value: 'active-vcp-discounts-for-party' },
                React.createElement(AccordionViewSection.Trigger, null, "aktywne rabaty VCP dla strony"),
                React.createElement(AccordionViewSection.Content, null,
                    React.createElement(ActiveVcpDiscounts, { activeVcpDiscounts: activeVcpDiscounts }))),
            React.createElement(AccordionViewSection.Item, { value: 'vcp-discount-settelements-for-car' },
                React.createElement(AccordionViewSection.Trigger, null, "rozliczenie rabatu VCP dla samochodu"),
                React.createElement(AccordionViewSection.Content, null,
                    React.createElement(VcpDiscountSettlement, { settlementsOfOrder: settlementsOfOrder }))),
            React.createElement(AccordionViewSection.Item, { value: 'other-extra-sale-discounts-for-party' },
                React.createElement(AccordionViewSection.Trigger, null, "rabaty DRS dla strony"),
                React.createElement(AccordionViewSection.Content, null,
                    React.createElement(DrsDiscountSection, { drsDiscountsForParty: drsDiscountsForParty })))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "extraSaleDiscountSubmitButtonWrapper" },
            React.createElement("div", { className: "extraSaleDiscountSubmitButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onCloseClick }, "zamknij"))),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
