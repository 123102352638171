export function selectedFundingForm(funding) {
    if (funding) {
        switch (funding.fundingForms.selected.id) {
            case 'DETAILED_LEASING':
                return "".concat(funding.fundingForms.selected.name, " - ").concat(funding.leasingProviders.selected.name);
            case 'CFM_FUNDING':
                return "".concat(funding.fundingForms.selected.name, " - ").concat(funding.cfmProviders.selected.name);
            default:
                return funding.fundingForms.selected.name;
        }
    }
}
