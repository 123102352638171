// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .extraSaleDiscountApplicationSectionWrapper {
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
    padding-inline: 16px;
    padding-block: 24px;
    color: var(--black-text-color);
}

#new-dol .extraSaleDiscountApplicationSectionGridWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-bottom: 16px;
}

#new-dol .extraSalceDiscountApplicationSectionGridColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

#new-dol .extraSaleDiscountApplicationLabel {
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--black-text-color);
}

#new-dol .extraSaleDiscountApplicationValue {
    color: var(--black-text-color);
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
}

#new-dol .extraSaleDiscountApplicationSubLabel {
    margin-top: 8px;
    font-size: 12px;
    padding-left: 16px;
    line-height: 18px;
    font-weight: 400;
}

#new-dol .extraSaleDiscountApplicationAttachmentItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/extraSaleDiscounts/extraSaleDiscountApplicationSection/extraSaleDiscountApplication.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+BAA+B;IAC/B,eAAe;IACf,oBAAoB;IACpB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".extraSaleDiscountApplicationSectionWrapper {\n    width: 100%;\n    font-family: var(--font-family);\n    font-size: 14px;\n    padding-inline: 16px;\n    padding-block: 24px;\n    color: var(--black-text-color);\n}\n\n.extraSaleDiscountApplicationSectionGridWrapper {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 32px;\n    margin-bottom: 16px;\n}\n\n.extraSalceDiscountApplicationSectionGridColumn {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.extraSaleDiscountApplicationLabel {\n    padding-left: 16px;\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 500;\n    margin-bottom: 8px;\n    color: var(--black-text-color);\n}\n\n.extraSaleDiscountApplicationValue {\n    color: var(--black-text-color);\n    padding-left: 16px;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.extraSaleDiscountApplicationSubLabel {\n    margin-top: 8px;\n    font-size: 12px;\n    padding-left: 16px;\n    line-height: 18px;\n    font-weight: 400;\n}\n\n.extraSaleDiscountApplicationAttachmentItem {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
