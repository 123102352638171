import React from 'react';
import './extraSaleDiscountApplication.css';
import { carDefinitionWithStockCategoryDescription } from 'common/carDefinition';
import { ChangesHistory } from '../changesHistory/ChangesHistory';
import { formatDate } from 'utils/formatDate';
import { TextAmount } from 'components/textAmount/TextAmount';
import { selectedFundingForm } from 'common/selectedFundingForm';
import { ApplicationReasons } from './ApplicationReasons';
import { List } from 'components/list/List.component';
import { openFile } from 'common/openFile';
import { useExtraSaleDiscountApi } from '../useExtraSaleDiscountApi';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
export var ExtraSaleDiscountApplication = withCancellation(function (_a) {
    var extraSaleDiscountApplicationPreviewSection = _a.extraSaleDiscountApplicationPreviewSection;
    var extraSaleDiscountApi = useExtraSaleDiscountApi();
    function handleFileNameClick(fileUrl, fileName) {
        extraSaleDiscountApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    return (React.createElement("div", { className: 'extraSaleDiscountApplicationSectionWrapper', "data-testid": 'extra-sale-discount-section' },
        React.createElement("div", { className: 'extraSaleDiscountApplicationSectionGridWrapper' },
            React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Samoch\u00F3d:"),
                extraSaleDiscountApplicationPreviewSection && extraSaleDiscountApplicationPreviewSection.car ?
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'extra-sale-discount-car' }, carDefinitionWithStockCategoryDescription(extraSaleDiscountApplicationPreviewSection.car)),
                        extraSaleDiscountApplicationPreviewSection.car.invoiceDate && React.createElement("div", { className: "extraSaleDiscountApplicationSubLabel" }, "Ten samoch\u00F3d zosta\u0142 ju\u017C zafakturowany przez VCP"))
                    : null)),
        React.createElement("div", { className: 'extraSaleDiscountApplicationSectionGridWrapper' },
            React.createElement("div", { className: 'extraSalceDiscountApplicationSectionGridColumn' },
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Data zam\u00F3wienia VISTA:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-factory-order-date' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.factoryOrderDate) ? formatDate(new Date(extraSaleDiscountApplicationPreviewSection.car.factoryOrderDate)) : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Data dostawy:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-delivery-date' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.deliveryDate) ? formatDate(new Date(extraSaleDiscountApplicationPreviewSection.car.deliveryDate)) : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Data wydania na umowie:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-planned-handover-date' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.plannedHandoverDate) ? formatDate(new Date(extraSaleDiscountApplicationPreviewSection.car.plannedHandoverDate)) : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Data wydania w DOL:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-handover-date' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.handoverDate) ? formatDate(new Date(extraSaleDiscountApplicationPreviewSection.car.handoverDate)) : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Cena katalogowa netto:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-net-list-price' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.netListPrice) ? React.createElement(TextAmount, { textAlignEnd: false }, extraSaleDiscountApplicationPreviewSection.car.netListPrice) : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Cena katalogowa brutto:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-gross-list-price' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.netListPrice) ? React.createElement(TextAmount, { textAlignEnd: false }, extraSaleDiscountApplicationPreviewSection.car.netListPrice * 1.23) : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Typ zam\u00F3wienia:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-detailed-type-code-description' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.car.detailedTypeCodeDescription) ? extraSaleDiscountApplicationPreviewSection.car.detailedTypeCodeDescription : '')),
                React.createElement("div", { className: 'extraSaleDiscountApplicationLabelValue' },
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Rodzaj finansowania:"),
                    React.createElement("div", { className: "extraSaleDiscountApplicationValue", "data-testid": 'drs-funding' }, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.funding) ? selectedFundingForm(extraSaleDiscountApplicationPreviewSection.funding) : ''))),
            React.createElement("div", { className: 'extraSalceDiscountApplicationSectionGridColumn' },
                React.createElement("div", null,
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Powody zg\u0142oszenia:"),
                    (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.applicationReasons) &&
                        React.createElement(ApplicationReasons, { carNetListPrice: extraSaleDiscountApplicationPreviewSection.car.netListPrice, applicationReasons: extraSaleDiscountApplicationPreviewSection.applicationReasons.selected })),
                React.createElement("div", null,
                    React.createElement("div", { className: "extraSaleDiscountApplicationLabel" }, "Za\u0142\u0105czniki:"),
                    React.createElement("div", null, (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.attachments.length) > 0 ? (React.createElement(List.Root, null, extraSaleDiscountApplicationPreviewSection.attachments.map(function (file) { return (React.createElement(List.Item, { key: file.location },
                        React.createElement("div", { className: "extraSaleDiscountApplicationAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.location, file.fileName); }, "data-testid": 'drs-request-attachment' }, file.fileName)))); }))) : null)))),
        (extraSaleDiscountApplicationPreviewSection === null || extraSaleDiscountApplicationPreviewSection === void 0 ? void 0 : extraSaleDiscountApplicationPreviewSection.changes) &&
            React.createElement(ChangesHistory, { changesHistory: extraSaleDiscountApplicationPreviewSection.changes })));
});
