import React from 'react';
import './drsDiscountSection.css';
import { TextAmount } from 'components/textAmount/TextAmount';
import ModelDictionary from 'common/modelDictionary';
import { extraSaleDiscountStatusName } from 'common/extraSaleDiscountStatusName';
export function DrsDiscountSection(_a) {
    var drsDiscountsForParty = _a.drsDiscountsForParty;
    return (drsDiscountsForParty &&
        React.createElement("div", { className: 'drsDiscountWrapper', "data-testid": 'drs-discounts-wrapper' },
            React.createElement("table", { className: "drsDiscountTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountDealerCell" }, "Dealer"),
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountCarCell" }, "Samoch\u00F3d"),
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountDiscountAmountNetCell" }, "Kwota rabatu netto"),
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountStatusCell" }, "Status"))),
                React.createElement("tbody", null, drsDiscountsForParty.map(function (drsDiscount, index) { return (React.createElement("tr", { className: 'drsDiscountTableRow', key: index },
                    React.createElement("td", { className: "drsDiscountBodyCell drsDiscountDealerCell" }, drsDiscount.dealerName),
                    React.createElement("td", { className: "drsDiscountBodyCell drsDiscountCarCell" }, "".concat(ModelDictionary.nameFor(drsDiscount.car.modelId.split('_')[0]), " (CON ").concat(drsDiscount.car.commonOrderNumber, ")")),
                    React.createElement("td", { className: "drsDiscountBodyCell drsDiscountDiscountAmountNetCell" },
                        React.createElement(TextAmount, { textAlignEnd: false }, drsDiscount.requestedNetDiscount)),
                    React.createElement("td", { className: "drsDiscountBodyCell drsDiscountStatusCell" }, extraSaleDiscountStatusName(drsDiscount.status)))); })))));
}
