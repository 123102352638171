import React from 'react';
import './applicantSection.css';
import { phoneNumber } from 'common/phoneNumber';
export function ApplicantSection(_a) {
    var applicantSectionData = _a.applicantSectionData;
    function shouldDataPreview(name) {
        if (name) {
            return !name.toLowerCase().includes('webcon');
        }
    }
    return (applicantSectionData &&
        React.createElement("div", { className: 'applicantSectionWrapper', "data-testid": 'applicant-section' },
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Nazwa dealera:"),
                React.createElement("p", { className: 'applicantSectionValue', "data-testid": 'applicant-dealer-id' }, applicantSectionData.organizationName)),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Wnioskuj\u0105cy:"),
                React.createElement("p", { className: 'applicantSectionValue', "data-testid": 'applicant-name' }, applicantSectionData.applicantName)),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Email:"),
                React.createElement("p", { className: 'applicantSectionValue', "data-testid": 'applicant-email' }, shouldDataPreview(applicantSectionData.applicantName) ? applicantSectionData.applicantEmail : '')),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Numer telefonu:"),
                React.createElement("p", { className: 'applicantSectionValue', "data-testid": 'applicant-phone' }, shouldDataPreview(applicantSectionData.applicantName) ? phoneNumber(applicantSectionData.applicantPhone) : '')),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Numer wniosku:"),
                React.createElement("p", { className: 'applicantSectionValue', "data-testid": 'application-number' }, applicantSectionData.applicationNumber))));
}
