var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from 'react';
import { useExtraSaleDiscountApi } from '../useExtraSaleDiscountApi';
import { useStatus } from 'common/statusContext';
import { format, sub } from 'date-fns';
import { dateNow } from 'utils/dateNow';
export function useExtraSaleDiscountPreview(_a) {
    var extraSaleDiscountId = _a.extraSaleDiscountId;
    var _b = useState(), extraSaleDiscountApplication = _b[0], setExtraSaleDisountApplication = _b[1];
    var _c = useState(), applicantCard = _c[0], setApplicantCard = _c[1];
    var _d = useState(), organizationData = _d[0], setOrganizationData = _d[1];
    var _e = useState(), partyCars = _e[0], setPartyCars = _e[1];
    var _f = useState(), settlementsOfOrder = _f[0], setSettlementsOfOrder = _f[1];
    var _g = useState(), drsDiscountsForParty = _g[0], setDrsDiscountsForParty = _g[1];
    var _h = useState(), activeVcpDiscounts = _h[0], setActiveVcpDiscounts = _h[1];
    var extraSaleDiscountApi = useExtraSaleDiscountApi();
    var setStatus = useStatus().setStatus;
    useEffect(function () {
        function getApplicantCardData(applicantId) {
            return __awaiter(this, void 0, void 0, function () {
                var applicantCardResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, extraSaleDiscountApi.getUserCardData(applicantId)];
                        case 1:
                            applicantCardResponse = _a.sent();
                            setApplicantCard(applicantCardResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getOrganizationData(dealerId) {
            return __awaiter(this, void 0, void 0, function () {
                var organizationDataResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, extraSaleDiscountApi.getOrganizationData(dealerId)];
                        case 1:
                            organizationDataResponse = _a.sent();
                            setOrganizationData(organizationDataResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getPartyCars(partyIdentificationNumber) {
            return __awaiter(this, void 0, void 0, function () {
                var formattedDayNow, dateFourYearsAgo, formattedFourYearsAgo, carsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formattedDayNow = format(dateNow(), 'yyyy-MM-dd');
                            dateFourYearsAgo = sub(dateNow(), { years: 4 });
                            formattedFourYearsAgo = format(dateFourYearsAgo, 'yyyy-MM-dd');
                            return [4 /*yield*/, extraSaleDiscountApi.getPartyCars(partyIdentificationNumber, formattedFourYearsAgo, formattedDayNow)];
                        case 1:
                            carsResponse = _a.sent();
                            setPartyCars(carsResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getActiveVcpDiscounts(identificationNumber) {
            return __awaiter(this, void 0, void 0, function () {
                var activeVcpDiscountsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, extraSaleDiscountApi.getActiveVcpDiscounts(identificationNumber)];
                        case 1:
                            activeVcpDiscountsResponse = _a.sent();
                            setActiveVcpDiscounts(activeVcpDiscountsResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getSettlementsOfOrder(commonOrderNumber) {
            return __awaiter(this, void 0, void 0, function () {
                var carDefinitonResponse, settlementsOfOrderResponse, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, extraSaleDiscountApi.getCarDefinition(commonOrderNumber)];
                        case 1:
                            carDefinitonResponse = _b.sent();
                            if (!carDefinitonResponse.orderId) return [3 /*break*/, 3];
                            return [4 /*yield*/, extraSaleDiscountApi.getVcpDiscountSettlementOfOrder(carDefinitonResponse.orderId)];
                        case 2:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            _a = undefined;
                            _b.label = 4;
                        case 4:
                            settlementsOfOrderResponse = _a;
                            setSettlementsOfOrder(settlementsOfOrderResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getDrsDiscountsForParty(identificationNumber) {
            return __awaiter(this, void 0, void 0, function () {
                var drsDiscountsForPartyResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, extraSaleDiscountApi.getDrsDiscountsForIdentificationNumber(identificationNumber)];
                        case 1:
                            drsDiscountsForPartyResponse = _a.sent();
                            setDrsDiscountsForParty(drsDiscountsForPartyResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getExtraSaleDiscountData() {
            return __awaiter(this, void 0, void 0, function () {
                var extraSaleDiscountDataResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!extraSaleDiscountId) return [3 /*break*/, 2];
                            setStatus('pending');
                            return [4 /*yield*/, extraSaleDiscountApi.getExtraSaleDiscountData(extraSaleDiscountId)];
                        case 1:
                            extraSaleDiscountDataResponse = _a.sent();
                            setExtraSaleDisountApplication(extraSaleDiscountDataResponse);
                            getApplicantCardData(extraSaleDiscountDataResponse.applicantId);
                            getOrganizationData(extraSaleDiscountDataResponse.dealerId);
                            getPartyCars(extraSaleDiscountDataResponse.party.identificationNumber.value);
                            getActiveVcpDiscounts(extraSaleDiscountDataResponse.party.identificationNumber.value);
                            getSettlementsOfOrder(extraSaleDiscountDataResponse.car.commonOrderNumber);
                            getDrsDiscountsForParty(extraSaleDiscountDataResponse.party.identificationNumber.value);
                            setStatus('success');
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        getExtraSaleDiscountData();
    }, [extraSaleDiscountId]);
    return {
        extraSaleDiscountApplication: extraSaleDiscountApplication,
        applicantCard: applicantCard,
        organizationData: organizationData,
        partyCars: partyCars,
        settlementsOfOrder: settlementsOfOrder,
        drsDiscountsForParty: drsDiscountsForParty,
        activeVcpDiscounts: activeVcpDiscounts
    };
}
