// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .carSalePotentialSectionWrapper {
    font-family: var(--font-family);
    font-size: 14px;
    padding-block: 24px;
    color: var(--black-text-color);
}

#new-dol .carSalePotentialSection {
    padding-left: 16px;
    display: flex;   
}

#new-dol .carSalePotentialSectionLabel {
    padding-left: 16px;
    font-weight: 500;
    padding-bottom: 8px;
}

#new-dol .carSalePotentialSectionValue {
    padding-left: 16px;
    padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/extraSaleDiscounts/carSalePotentialSection/carSalePotentialSection.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,eAAe;IACf,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".carSalePotentialSectionWrapper {\n    font-family: var(--font-family);\n    font-size: 14px;\n    padding-block: 24px;\n    color: var(--black-text-color);\n}\n\n.carSalePotentialSection {\n    padding-left: 16px;\n    display: flex;   \n}\n\n.carSalePotentialSectionLabel {\n    padding-left: 16px;\n    font-weight: 500;\n    padding-bottom: 8px;\n}\n\n.carSalePotentialSectionValue {\n    padding-left: 16px;\n    padding-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
