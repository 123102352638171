import React from 'react';
import { formatDate } from 'utils/formatDate';
import { immutableSort } from 'utils/immutableSort';
import './changesHistory.css';
import { extraSaleDiscountApplicationChangeName } from 'common/extraSaleDiscountApplicationChangeName';
import { TextAmount } from 'components/textAmount/TextAmount';
import _ from 'lodash';
export function ChangesHistory(_a) {
    var changesHistory = _a.changesHistory;
    var historyData = changesHistory;
    var sortedHistoryData = immutableSort(historyData, function (a, b) {
        var dateA = new Date(a.timestamp).getTime();
        var dateB = new Date(b.timestamp).getTime();
        return dateA - dateB;
    });
    function getDate(timestamp) {
        var date = timestamp.split(' ')[0];
        var formattedDate = formatDate(new Date(date));
        return formattedDate;
    }
    function getTime(timestamp) {
        var time = timestamp.split(' ')[1];
        var _a = time.split(':'), hour = _a[0], minutes = _a[1], seconds = _a[2];
        var formattedTime = hour + ':' + minutes;
        return formattedTime;
    }
    function formatNumber(value) {
        if (_.isFinite(value)) {
            return new Intl.NumberFormat('pl-PL', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(value);
        }
        else {
            return undefined;
        }
    }
    function roundedPercentage(dividend, divisor) {
        return formatNumber(dividend / divisor * 100);
    }
    return React.createElement("div", { className: 'extraSaleDiscountChangesHistoryWrapper' },
        React.createElement("div", { className: 'extraSaleDiscountChangesHistoryHeader' }, "historia zmian"),
        React.createElement("div", { className: 'extraSaleDiscountchangesHistoryTableWrapper', "data-testid": 'extra-sale-discount-changes-history-table-wrapper' },
            React.createElement("table", { className: 'extraSaleDiscountChangesHistoryTable' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'extraSaleDiscountChangesHistoryTableHeadCell extraSaleDiscountChangesHistoryTableDateCell' }, "Data i godzina"),
                        React.createElement("th", { className: 'extraSaleDiscountChangesHistoryTableHeadCell extraSaleDiscountChangesHistoryTableStatusCell', style: { paddingRight: '12px' } }, "Status"),
                        React.createElement("th", { className: 'extraSaleDiscountChangesHistoryTableHeadCell extraSaleDiscountChangesHistoryTableCommentCell' }, "Komentarz"),
                        React.createElement("th", { className: 'extraSaleDiscountChangesHistoryTableHeadCell extraSaleDiscountChangesHistoryTableRequestedNetDiscountCell' }, "Wnioskowana kwota"),
                        React.createElement("th", { className: 'extraSaleDiscountChangesHistoryTableHeadCell extraSaleDiscountChangesHistoryTableDiscountAmountCell' }, "Warto\u015B\u0107 rabatu (%)"),
                        React.createElement("th", { className: 'extraSaleDiscountChangesHistoryTableHeadCell extraSaleDiscountChangesHistoryTableAuthorCell' }, "Wprowadzaj\u0105cy"))),
                React.createElement("tbody", null, sortedHistoryData.map(function (statusChange, index) { return (React.createElement("tr", { className: 'extraSaleDiscountChangesHistoryTableRow', key: index },
                    React.createElement("td", { className: 'extraSaleDiscountChangesHistoryTableBodyCell extraSaleDiscountChangesHistoryTableDateCell' },
                        React.createElement("div", { className: 'extraSaleDiscountChangesHistoryDateAndTimeWrapper' }, statusChange.timestamp ?
                            React.createElement(React.Fragment, null,
                                React.createElement("div", null, getDate(statusChange.timestamp)),
                                React.createElement("div", null, getTime(statusChange.timestamp)))
                            : null)),
                    React.createElement("td", { className: 'extraSaleDiscountChangesHistoryTableBodyCell extraSaleDiscountChangesHistoryTableStatusCell' }, extraSaleDiscountApplicationChangeName(statusChange.change)),
                    React.createElement("td", { className: 'extraSaleDiscountChangesHistoryTableBodyCell extraSaleDiscountChangesHistoryTableCommentCell' }, statusChange.comment),
                    React.createElement("td", { className: 'extraSaleDiscountChangesHistoryTableBodyCell extraSaleDiscountChangesHistoryTableRequestedNetDiscountCell' },
                        React.createElement(TextAmount, { textAlignEnd: false }, statusChange.requestedNetDiscount)),
                    React.createElement("td", { className: 'extraSaleDiscountChangesHistoryTableBodyCell extraSaleDiscountChangesHistoryTableDiscountAmountCell' }, roundedPercentage(statusChange.requestedNetDiscount, statusChange.netListPrice)),
                    React.createElement("td", { className: 'extraSaleDiscountChangesHistoryTableBodyCell extraSaleDiscountChangesHistoryTableAuthorCell' }, statusChange.applier))); })))));
}
